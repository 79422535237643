.App {
  text-align: center;
}

.App-logo {
  height: 15vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #FFFFFF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: #000000;
}

.App-link {
  color: #61dafb;
}

.container .box {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
}

.container .box .box-row .box-cell .pageContent {
  width: 100%;
  margin: 10px 0;
  padding: 15px;
  color: black;
  text-align: justify;
  font-size: medium;
  box-sizing: border-box;
}

.container .box .box-row .box-cell .image {
  width: 100%;
  max-width: 300px;
  margin: 10px 10px auto;
  color: black;
  text-align: justify;
  vertical-align: top;
}

.container .box .box-row .box-cell .elaboration {
  width: 100%;
  margin: 10px 0;
  color: black;
  text-align: center;
  font-size: medium;
  box-sizing: border-box;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

pre .question .triviaQuestion {
  font-style: normal;
  font-size: medium;
  vertical-align: baseline;
  margin: 2%;
  padding: 15px;
  box-sizing: border-box;
  text-align: center;
}

.border {
  border: 1px solid #ccc;
  border-radius: 0.2rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid blue;
  border-bottom-color: transparent;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  display: block;
}

@keyframes rotation {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@media screen and (max-width: 767px) {
  .container .box {
    flex-direction: column;
    padding: 1px;
    margin: 0 1px;
  }

  .container .box .box-row .box-cell .pageContent {
    width: 50%;
    font-size: 3px;
    line-height: 1;
  }

  .container .box .box-row .box-cell .elaboration {
    width: 50%;
    font-size: 3px;
    padding: 0px;
  }

  pre h3 .question .triviaQuestion {
    font-style: normal;
    font-size: xx-small;
    margin: 0.2%;
    padding: 5px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: justify;
    text-justify: inter-word;
    overflow-wrap: break-word;
  }

  .inputString {
    width: 100%;
    height: 1.5rem;
    padding: 0.2rem;
    font-size: 0.8rem;
    background-color: white;
    color: black;
  }

  .border {
    border: 1px solid #ccc;
    border-radius: 0.2rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
}